<template>
  <navigation-bar />
  <div id="content">
    <router-view />
  </div>
  <footer-bar />
</template>

<script>
import NavigationBar from "@/components/NavigationBar.vue";
import FooterBar from "@/components/FooterBar.vue";

export default {
  name: "App",
  components: {
    NavigationBar,
    FooterBar,
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "NotoSansKannada-Variable";
  src: url("@/assets/fonts/NotoSansKannada-VariableFont_wdth,wght.ttf")
    format("truetype");
}

@font-face {
  font-family: "Regarn-Light";
  src: url("@/assets/fonts/Regarn-Light.ttf") format("truetype");
}

html {
  box-sizing: border-box;
}

body {
  font-size: 16px;
  margin: 0px;
  padding: 0px;
  overflow-y: scroll;
  background: #fffdfc;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

a {
  color: inherit;
  text-decoration: inherit;
}

#app {
  font-family: "Regarn-Light";
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  position: relative;
}

.description {
  font-family: "NotoSansKannada-Variable";
  text-align: left;
}

.white-space-pre {
  white-space: pre;
}

.background-contain-center-no-repeat {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.content-tile {
  margin-top: 120px;
  min-height: calc(100vh - 120px);
}

@media only screen and (min-width: 1920px) {
  #content {
    max-width: 1920px;
    margin: auto;
  }
}
</style>
