<template>
  <div id="navbar">
    <div id="navbar-eye-icon" class="background-contain-center-no-repeat"></div>
    <div id="navbar-menu">
      <input id="hamburgerSwitch" type="checkbox" />
      <span></span>
      <span></span>
      <span></span>
      <div class="nav-container">
        <nav>
          <router-link to="/" class-active="active">Home</router-link>
          <router-link to="/about" class-active="active">About</router-link>
          <router-link to="/work" class-active="active">Work</router-link>
          <router-link to="/contact" class-active="active">Contact</router-link>
          <div class="sun-decoration background-contain-center-no-repeat"></div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavigationBar",
  mounted() {
    window.addEventListener("resize", this.restartHamburgerSwitch);

    var anchors = document.querySelectorAll(
      "#navbar-menu > .nav-container > nav > a"
    );

    anchors.forEach((anchor) => {
      anchor.addEventListener("click", this.restartHamburgerSwitch, false);
    });
  },
  unmounted() {
    window.removeEventListener("resize", this.restartHamburgerSwitch);
  },
  methods: {
    restartHamburgerSwitch() {
      document.getElementById("hamburgerSwitch").checked = false;
    },
  },
};
</script>

<style lang="scss">
#navbar {
  padding: 40px 70px;
  position: fixed;
  top: 0;
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, #fffdfc00 0%, #fffdfc80 15%, #fffdfcff 75%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 22px;
  width: 100%;

  $margin: 0.2em;
  $max-width: calc(100% + 2 * $margin);
  transition: padding 0.8s ease-in-out;
  z-index: 111;

  #navbar-eye-icon {
    width: 70px;
    height: 40px;
    flex: none;
    background-image: url("@/assets/icons/eye.png");
    z-index: 200;
  }

  #navbar-menu {
    & > #hamburgerSwitch,
    & > span {
      display: none;
    }
  }

  nav {
    display: flex;
    justify-content: space-between;
    width: 540px;
    padding-top: 10px;

    .expand-bar {
      width: calc($max-width * 1);
      transform: translate(-$margin, 0.5em);
      transition-delay: 0;
    }

    .shrink-bar-active {
      width: calc($max-width * 0);
      transition-delay: 0.1s;
    }

    a {
      width: max-content;
      position: relative;

      &::before {
        content: "-";
        color: transparent;
        width: 0;
        height: 0.05em;
        background-color: #000;
        position: absolute;
        transition: width 0.2s ease-in-out;
        transform: translate(-$margin, 0.5em);
      }

      &.router-link-active {
        transition-delay: width 0.1s;
        &::before {
          @extend .expand-bar;
        }
      }

      &:hover {
        &::before {
          @extend .expand-bar;
        }
        & ~ a.router-link-active::before {
          @extend .shrink-bar-active;
        }
      }

      &.router-link-active:has(~ a:hover)::before {
        @extend .shrink-bar-active;
      }
    }
  }
}

// Mobile
@media only screen and (max-width: 840px) {
  #navbar {
    $padding: 32px;
    font-size: 6vw;
    padding: $padding;

    #navbar-eye-icon {
      width: 8vw;
      height: 5vw;
      min-width: 50px;
      min-height: 30px;
    }

    #navbar-menu {
      position: fixed;
      top: $padding;
      right: $padding;
      z-index: 100;

      #hamburgerSwitch {
        display: block;
        position: absolute;
        width: 40px;
        height: 40px;
        top: 0;
        right: 0;
        cursor: pointer;
        opacity: 0; // hide this
        z-index: 12; // and place it over the hamburger
        -webkit-touch-callout: none;

        //Rotate hamburger
        &:checked ~ span {
          opacity: 1;
          transform: rotate(45deg) translate(-2px, -1px);
        }

        &:checked ~ span:nth-last-child(3) {
          opacity: 0;
          transform: rotate(0deg) scale(0.2, 0.2);
        }

        &:checked ~ span:nth-last-child(2) {
          transform: rotate(-45deg) translate(0, -1px);
        }

        // Enable nav
        &:checked ~ .nav-container nav {
          transform: translateX(-120%);
        }
      }

      // Hamburger start
      span {
        display: block;
        width: 40px;
        height: 3px;
        margin: 9px 0;
        position: relative;
        background: #000;
        z-index: 11;
        transform-origin: 3px 0px;
        transition: transform 0.4s ease-in-out, background 0.4s ease-in-out,
          opacity 0.5s ease;
      }

      span:first-child {
        transform-origin: 0% 0%;
      }

      span:nth-last-child(2) {
        transform-origin: 0% 100%;
      }
      // Hamburger end

      a {
        margin: 6vw 0;
      }

      .nav-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 10;
        transform: translateX(120%);

        nav {
          width: 100%;
          height: 100%;
          padding-top: calc(100px + 1em);
          flex-direction: column;
          justify-content: start;
          align-items: center;
          background: #fffdfc;
          box-shadow: -4px 0px 32px 0px #00000022;
          transition: transform 0.5s ease-in-out;

          a::after {
            content: "-";
            color: transparent;
            position: absolute;
            width: calc(50vw - 3em);
            height: 0.5em;
            transform: translate(-50vw, -50%);
            left: 50%;
            top: calc(50% - 0.1em);
            background-image: url("@/assets/icons/mobile/arrow-navbar.png");
            background-size: cover;
            background-position: right;
            background-repeat: no-repeat;
          }

          .sun-decoration {
            width: 24vw;
            height: 24vw;
            background-image: url("@/assets/icons/sun.png");
            margin-top: 12vw;
            transform: rotate(3deg);
          }
        }
      }
    }
  }
}
</style>
