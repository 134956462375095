<template>
  <footer>© 2023 Klaudia Hańderek _____ All Rights Reserved</footer>
</template>

<script>
export default {
  name: "FooterBar",
};
</script>

<style lang="scss">
footer {
  position: absolute;
  bottom: 0;
  font-family: "NotoSansKannada-Variable";
  padding: 40px 70px;
  text-align: left;
  z-index: 20;
}

@media only screen and (max-width: 540px) {
  footer {
    width: 100%;
    font-size: 3.2vw;
    text-align: center;
    padding: 20px 0px;
  }
}
</style>
