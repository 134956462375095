<template>
  <div class="home content-tile">
    <div class="home-description-container">
      <div class="title polish-latin-text">
        KLAUDIA<br />
        HA<span class="polish-latin-line">N</span>DEREK
      </div>
      <div class="description">
        PAINTER AND SCUPLTOR <br />
        BASED IN POLAND
        <span
          class="description-arrow background-contain-center-no-repeat"
        ></span>
        KATOWICE
      </div>
    </div>
    <div class="home-decoration">
      <div class="sun-decoration background-contain-center-no-repeat"></div>
      <div class="image-decoration"></div>
      <div class="black-box"></div>
    </div>
  </div>
</template>

<style lang="scss">
.home {
  display: flex;
  justify-content: space-between;

  .home-description-container {
    margin: 240px 70px 120px 70px;
    .polish-latin-text {
      position: relative;
    }

    .polish-latin-line::before {
      content: "-";
      position: absolute;
      font-size: 0.8em;
      transform: translate(0.35em, -0.55em) rotate(-60deg) scale(0.75, 1);
    }

    .title {
      font-size: 120px;
      text-align: left;
      white-space: nowrap;
    }

    .description-arrow {
      display: inline-block;
      width: 130px;
      height: 16px;
      background-image: url("@/assets/icons/arrow-description.png");
      transform: translateY(0.2em);
    }
  }

  .home-decoration {
    $image-width: 540px;
    $image-height: 600px;

    width: calc($image-width + 180px);
    height: calc($image-width + 210px);
    margin-top: 180px;
    position: relative;

    .sun-decoration {
      width: 175px;
      height: 175px;
      background-image: url("@/assets/icons/sun.png");
      position: absolute;
      top: -5px;
      left: -5px;
      z-index: 2;
      transform: translate(-50%, -50%) rotate(3deg);
    }

    .image-decoration {
      width: $image-width;
      height: $image-height;
      background-image: url("@/assets/klaudia/home.jpg");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }

    .black-box {
      width: $image-width;
      height: $image-width;
      position: absolute;
      top: 240px;
      right: 0;
      background-color: #000;
      z-index: 0;
    }
  }
}

@media only screen and (max-width: 1490px) {
  .home {
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: calc(40px + 4vw);

    .home-description-container {
      margin: 8vw 0;

      & * {
        text-align: center !important;
      }

      .title {
        font-size: 12vw !important;
      }

      .description {
        margin-top: 4vw;
        font-size: 2.5vw;

        .description-arrow {
          width: 13vw;
          height: 1.6vw;
          transform: none;
        }
      }
    }

    .home-decoration {
      width: 100%;
      min-width: 100%;
      height: 100vw;
      margin-top: 0;

      .sun-decoration {
        display: none;
      }

      .image-decoration {
        width: 70vw;
        height: 70vw;
      }

      .black-box {
        width: 55vw;
        height: 55vw;
        top: 35vw;
      }
    }
  }
}
</style>
